import { For, Show } from 'solid-js'
import { Flex } from '../../components/ui/Flex'
import { createHttpResource } from '#/utils/createHttpResource'
import { LogoutButton } from '#/auth/ui/LogoutButton'
import { useTrpcQuery } from '#/trpc/useTrpcQuery'

import { navigate } from 'vike/client/router'
import {
  useSubscriptionActions,
  useSubscriptionState,
} from '#/subscription/state/SubscriptionProvider'

export default function Page() {
  const [songs] = createHttpResource(
    () => `${location.origin}/public/songs/index.json`,
    {
      responseType: 'json',
      transformResponse: (response) =>
        response as {
          name: string
          songUrl: string
        }[],
    },
  )
  const subscription = useSubscriptionState()
  const { setIsDialogOpen } = useSubscriptionActions()

  const handleClickSong = (song: { name: string; songUrl: string }) => {
    console.log({
      ...subscription,
    })

    if (subscription.hasActiveSubscription) {
      navigate(`/songs/${song.name}`)
    } else if (subscription.hasActiveSubscription === false) {
      setIsDialogOpen(true)
    }
  }

  return (
    <Flex
      direction="column"
      style={{ padding: `16px` }}
    >
      <LogoutButton />
      <h1>SazTunes</h1>

      <h2>Songs:</h2>
      <Show when={!subscription.isLoading && songs()}>
        {(getSongs) => (
          <ul>
            <For each={getSongs()}>
              {(song) => (
                <li>
                  <button onClick={() => handleClickSong(song)}>
                    {song.name}
                  </button>
                </li>
              )}
            </For>
          </ul>
        )}
      </Show>
    </Flex>
  )
}
